import "intersection-observer";
import swiper from "./swiper";
import lazyload from "./dal-lazyload";
import navigation from "./navigation";
// eslint-disable-next-line no-undef
require("./autoload"); //enforce to copy all images of src into web folder 

(function () {

	navigation.init();
	lazyload.init();
	swiper.init();

})();