import Swiper, { Autoplay, EffectFade, Pagination } from "swiper";
import zoomSliderImages from "./zoomSliderImages";

let inizializedSwiper;

export default {

	init() {

		Swiper.use([Pagination, Autoplay, EffectFade]);
		const container = document.querySelector(".slider-container");

		// for swiper to work uncomment the vendors loading in base-web-layout.twig
		inizializedSwiper = new Swiper(".slider-container", {
			loop: true,
			slidesPerView: 1,
			spaceBetween: 0,
			autoplay: true,
			effect: "fade",
			speed: 800,
			fadeEffect: {
				crossFade: true,
			},
			pagination: {
				el: ".swiper-pagination",
				clickable: true,
			},
		});

		if (container) {
			zoomSliderImages.init();
		}
	},
	disable() {
		if (inizializedSwiper.length) {
			inizializedSwiper.forEach(function (element) {
				element.destroy();
			});
		} else {
			inizializedSwiper.destroy();
		}
	},
}
