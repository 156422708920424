export default {
	init() {
		// Higher number = more zoom
		let scaleAmount = 0.5;
		const images = document.querySelectorAll(".swiper-slide .image-container img");
		scaleAmount = scaleAmount / 100;
		const viewportHeight = window.innerHeight;

		const observerConfig = {
			rootMargin: "0% 0% 0% 0%",
			threshold: buildThresholdList(),
		};

		// Create separate IntersectionObservers and scroll event listeners for each image
		// so that we can individually apply the scale only if the image is visible
		images.forEach(image => {
			let isVisible = false;
			let startedAnimationFrame = false;
			let zoomAnimationFrame;
			let doZoom;
			let parent = image.parentNode.parentNode;
			const borderHeight = parseFloat(getComputedStyle(parent).getPropertyValue("border-bottom-width")) + parseFloat(getComputedStyle(image).getPropertyValue("border-top-width"));


			image.style.transform = `scale(${1 + scaleAmount * percentageSeen(image, parent, borderHeight)})`;

			function zoomImage() {
				image.style.transform = `scale(${1 + scaleAmount * percentageSeen(image, parent, borderHeight)})`;

				if (doZoom) {
					zoomAnimationFrame = window.requestAnimationFrame(function () {
						zoomImage();
					});
				}
			}

			const observer = new IntersectionObserver((elements) => {
				elements.forEach(element => {
					isVisible = element.isIntersecting;

					if (isVisible && element.intersectionRatio > 0.2) {
						doZoom = true;
						if (!startedAnimationFrame) {
							startedAnimationFrame = true;
							zoomAnimationFrame = window.requestAnimationFrame(function () {
								zoomImage();
							});
						}
					} else {
						cancelAnimationFrame(zoomAnimationFrame);
						doZoom = false;
						startedAnimationFrame = false;
					}
				});
			}, observerConfig);

			observer.observe(image);
		});

		function buildThresholdList() {
			let thresholds = [];
			let numSteps = 20;

			for (let i = 1.0; i <= numSteps; i++) {
				let ratio = i / numSteps;
				thresholds.push(ratio);
			}

			thresholds.push(0);
			return thresholds;
		}


		// Calculates the "percentage seen" based on when the image first enters the screen until the moment it leaves
		// Here, we get the parent node position/height instead of the image since it's in a container that has a border, but
		// if your container has no extra height, you can simply get the image position/height
		function percentageSeen(element, parent, borderHeight) {
			let parentTop = parent.getBoundingClientRect().top;
			const scrollY = window.scrollY;
			const elPosY = parentTop + scrollY;
			const elHeight = parent.offsetHeight + borderHeight;


			if (elPosY > (scrollY + viewportHeight)) {
				// If we haven't reached the image yet
				return 0;
			} else if ((elPosY + elHeight) < scrollY) {
				// If we've completely scrolled past the image
				return 100;
			} else {
				// When the image is in the viewport
				const distance = scrollY + viewportHeight - elPosY;
				let percentage = distance / ((viewportHeight + elHeight) / 100);
				percentage = Math.round(percentage);

				return percentage;
			}
		}
	},
}